
  import { Component, Vue } from "vue-property-decorator";
  import { IUserSignUp } from "@/interfaces";
  import { required, confirmed, email } from "vee-validate/dist/rules";
  import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
  import countries from "@/countries";
  import { dispatchUserSignUp } from "@/store/main/actions";
  import { readIsLoggedIn } from "@/store/main/getters";
  import NDA from "@/components/NDA.vue";
  import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
  import { store } from "@/store";

  const invalidInputValidator = {
    validate(value) {
      if (value.includes("http") || value.includes("/")) {
        return false;
      }
      return true;
    },
  };

  // register validation rules
  extend("required", { ...required, message: "{_field_} can not be empty" });
  extend("confirmed", { ...confirmed, message: "Passwords do not match" });
  extend("email", { ...email, message: "Invalid email address" });
  extend("invalid_input", { ...invalidInputValidator, message: "Invalid" });
  extend("checked", {
    validate(value) {
      return {
        required: true,
        valid: value === true,
      };
    },
    computesRequired: true,
    message: "Must agree to continue",
  });

  @Component({
    components: {
      ValidationObserver,
      ValidationProvider,
      NDA,
      VueTelInputVuetify,
    },
  })
  export default class SignUp extends Vue {
    $refs!: {
      observer: InstanceType<typeof ValidationObserver>;
    };

    accessTo = [
      "A network of thousands of other MDTP providers.",
      "A private Facebook community to share cases and treatment information.",
      "Our new app to help monitor and score treatment progress.",
      "A discussion board to share information.",
      "Additional planned support mechanisms (periodic webinars, case postings, etc.)",
    ];

    countries = countries;

    public valid = false;
    public firstName = "";
    public lastName = "";
    public email = "";
    public phone = "";
    public placeOfEmployment = "";
    public town = "";
    public province = "";
    public country = "";
    public setPassword = false;
    public password1 = "";
    public password2 = "";
    public agreedToS = false;
    public epassword = "";
    signature = "";
    agreedNDA = false;
    dialog = false;
    phoneDetail = {
      number: "",
      valid: false,
      country: undefined,
    };

    public beforeRouteEnter(to, from, next) {
      if (readIsLoggedIn(store)) {
        next("/main/dashboard");
      } else if (readIsLoggedIn(store) === false) {
        next();
      }
    }

    public async onSubmit() {
      const success = await this.$refs.observer.validate();

      if (!success) {
        return;
      }

      if (
        this.epassword &&
        this.epassword !== "13" &&
        this.epassword.toLowerCase() !== "thirteen"
      ) {
        return;
      }

      const userProfile: IUserSignUp = {
        email: this.email,
      };

      if (this.firstName) {
        userProfile.first_name = this.firstName;
      }
      if (this.lastName) {
        userProfile.last_name = this.lastName;
      }
      if (this.email) {
        userProfile.email = this.email;
      }
      if (this.phone && this.phoneDetail.number) {
        userProfile.phone = this.phoneDetail.number;
      }
      if (this.town) {
        userProfile.town = this.town;
      }
      if (this.province) {
        userProfile.province = this.province;
      }
      if (this.country) {
        userProfile.country = this.country;
      }
      if (this.placeOfEmployment) {
        userProfile.place_of_employment = this.placeOfEmployment;
      }

      userProfile.nda_signature = this.signature;
      userProfile.password = this.password1;
      await dispatchUserSignUp(this.$store, userProfile);
    }

    agreeToS() {
      this.agreedNDA = true;
      this.dialog = false;
    }

    disagreeToS() {
      this.agreedNDA = false;
      this.dialog = false;
    }

    onPhoneInput(_, { number, valid, country }) {
      this.phoneDetail.number = number.international;
      this.phoneDetail.valid = valid;
      this.phoneDetail.country = country && country.name;
    }
  }
