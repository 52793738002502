<template>
  <div>
    <h3 class="text-h3 text-center">Non-Disclosure Agreement (NDA)</h3>
    <p class="font-weight-bold mt-1">
      CONFIDENTIALITY AND NON-COMPETE AGREEMENT BETWEEN FLORIDA DYSPHAGIA INSTITUTE AND
      MDTP SEMINAR PARTICIPANT
    </p>
    <ul>
      <li>
        WHEREAS, Therapist acknowledges and agrees that it has received and will
        continue to receive both copyrighted and proprietary and confidential
        information from MDTP that is the valuable, exclusive, and unique property of
        MDTP;
      </li>
      <li>
        WHEREAS, Therapist acknowledges and understands that only individuals directly
        Certified by MDTP are entitled to use the program, or in some jurisdictions its
        trade or service mark, MDTP, and any non-sanctioned use of such mark is a
        potential infringement of MDTP’s intellectual property;
      </li>
      <li>
        WHEREAS, Therapist acknowledges and agrees that MDTP would be materially harmed
        by the misappropriation or misuse of its copyrights, certification or trademark,
        and proprietary and confidential information in competition with MDTP; and
      </li>
      <li>
        WHEREAS, Therapist acknowledges and agrees that MDTP would not agree to train
        the Therapist in the MDTP methods and techniques without Therapist’s express
        agreement to maintain the confidentiality of MDTP’s proprietary information and
        not infringe on its business relations; and
      </li>
      <li>
        WHEREAS, Therapist and MDTP acknowledge that the widespread counseling of
        dysphagic patients with MDTP methods by MDTP trained speech therapists is in the
        best interests of MDTP, Therapist and Therapist’s patients.
      </li>
    </ul>
    <p class="mt-3">
      NOW THEREFORE, in consideration of the mutual covenants and agreements contained
      herein, and for other good and valuable consideration, the parties hereto agree as
      follows:
    </p>
    <ol>
      <li>
        MDTP agrees to provide Therapist specific training in the MDTP program of
        treatment for individuals with swallowing disorders related to stroke, TBI,
        head/ neck cancer and other related disorders resulting in dysphagia. This
        training will include the dissemination of proprietary and confidential
        information as well as the delivery and/or presentation of copyrighted
        materials, including one or more of MDTP Training and Workshop Binder, MDTP
        Evaluation Packet Pre-Treatment, and MDTP Training and Workshop Slides.
      </li>
      <li>
        Therapist is granted authority to utilize the MDTP methods in Therapist’s
        speech/swallowing therapy sessions with individual patients in the manner
        prescribed in the MDTP Training and Workshop materials. In addition, the
        Therapist may use the MDTP logo and reference being an MDTP Certified Therapist
        in Therapist’s marketing materials and advertising, including business cards,
        stationery and signage. Therapist is authorized to deliver MDTP training in a
        live, face-to-face mode only. Therapist is not authorized or certified to
        deliver MDTP Training via any alternative or additional technical means, such as
        webcam, telephone, video phone, software program, etc. in place of or in
        addition to live clinical sessions.
      </li>
      <li>
        The Therapist hereby agrees that it will not disclose MDTP’s proprietary or
        confidential information to any third parties for any purpose except as follows:
        Therapist may utilize all information learned in the MDTP Workshop with
        Therapist’s patients or prospective patients while delivering or explaining the
        MDTP treatment to such patients or prospective patients. Specific documents or
        parts of documents may be copied and provided to patients in the course of
        therapy only as designated by MDTP during or after the MDTP Workshop.
      </li>
      <li>
        Therapist may not disclose, train, teach, instruct or provide any materials to
        any third party in the MDTP technique for the purpose of competing with MDTP or
        having such third party compete with MDTP in the delivery of MDTP training and
        certification or to conduct patient speech therapy or train speech therapists
        utilizing the MDTP techniques. Therapist agrees not to provide and/or copy any
        MDTP materials, including any copying technology, electronic photocopying or
        electronic transmission, to others. General discussions in broad terms about the
        MDTP method are permitted so long as specific step-by-step details as found in
        and/or reflective of the content of proprietary materials are avoided. Formal
        presentations to inform colleagues about MDTP are permitted as long as
        Therapists use approved and provided MDTP presentation materials. <br />
        <br />
        Further, faculty and clinical supervisors of accredited higher education
        institutions providing formal (ASHA-approved/recognized) degree programs related
        to speech/voice therapy
        <span class="font-weight-bold"> are permitted</span> to provide general
        information about the MDTP therapy method in their formal
        classroom/academic/research capacities. They are
        <span class="font-weight-bold"> not permitted</span> to provide certification or
        qualifications in MDTP to any third party (including students). Use of all MDTP
        related materials for academic teaching purposes must be fully disclosed on all
        documents /media.)
      </li>
      <li>
        The Therapist agrees and acknowledges that the covenants and restrictions
        contained herein are necessary to prevent irreparable harm to MDTP, and that in
        the event of any breach or threatened breach by the Therapist of any of the
        foregoing provisions hereof, MDTP shall be entitled, if it so elects, (in
        addition to any other remedies it may have, including, but not limited to, a
        claim for money damages) to institute and prosecute proceedings in any court of
        competent jurisdiction to enjoin such breach or threatened breach, to enforce
        specific performance of such covenant or restriction, or to obtain any other
        relief available to MDTP for such breach or threatened breach.
      </li>
      <li>
        Therapist acknowledges that only Therapist is responsible for evaluating and
        treating Therapist’s patients and MDTP can have no role or responsibility in
        such evaluation or delivery of Therapist’s services. Therapist agrees that MDTP
        and its officers and directors, in developing and providing training for the
        MDTP methods, cannot anticipate every medical/health circumstance/condition of
        an individual patient, and will provide written notice to each individual
        patient that MDTP and its officers and directors in developing and providing
        training to Therapist cannot anticipate every medical/health
        circumstance/condition of an individual patient. Therapist absolves and
        indemnifies MDTP and its officers and directors of legal responsibility related
        in any way to Therapist’s therapy sessions.
      </li>
      <li>
        No change or modification of this Agreement shall be valid unless the same shall
        be in writing and signed by all the parties hereto; the parties acknowledge that
        this is the complete and final expression of their Agreement.
      </li>
      <li>
        No waiver of any breach of any condition herein shall constitute a waiver of any
        subsequent breach.
      </li>
      <li>
        This Agreement shall be binding upon and inure to the benefit of the parties
        hereto, their heirs, executors, administrators, successors, and assigns.
      </li>
      <li>
        Therapist shall indemnify MDTP, its officers and directors, and hold them
        harmless from and against any and all damages, fines, levies, suits,
        proceedings, claims, actions, and causes of action of any kind and of whatsoever
        nature, including, but not limited to, all costs, court costs, litigation
        expenses, and reasonable attorneys’ fees arising from, in connection with, or
        attributable to any breach of this Agreement.
      </li>
      <li>
        The invalidity or unenforceability of any particular provision of this Agreement
        shall not affect the validity or enforceability of the other provisions of this
        Agreement, and this Agreement shall be interpreted in all respects as if such
        invalid or unenforceable parts were omitted.
      </li>
      <li>
        This Agreement shall be governed and construed in accordance with the laws of
        the State of Florida.
      </li>
      <li>
        This agreement must be electronically signed prior to admission to an MDTP
        Certification Seminar.
      </li>
    </ol>
    <p class="mt-3">
      The parties hereto have executed this Agreement the day and year upon which
      electronic signature/agreement was completed.
    </p>
  </div>
</template>

<script>
  import { Component, Vue } from "vue-property-decorator";

  @Component
  export default class NDA extends Vue {}
</script>

<style></style>
